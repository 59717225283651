<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon flaticon2-architecture-and-city"></em>
          <span class="menu-text">{{ $t("menu.dashboard") }}</span>
        </a>
      </li>
    </router-link>
    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/users')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-user"></i>
        <span class="menu-text"> {{ $t("menu.users") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/users/all-users" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.allUsers") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/users/ap-users" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.apUsers") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/users/influencers" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.influencers") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!--    <router-link to="/users" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far fa-user"></em>
          <span class="menu-text">{{ $t('menu.users') }}</span>
        </a>
      </li>
    </router-link>
    <router-link to="/influencers" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon fa fa-users"></em>
          <span class="menu-text">{{ $t('menu.influencers') }}</span>
        </a>
      </li>
    </router-link>-->
    <router-link to="/shops" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon2-shopping-cart-1"></em>
          <span class="menu-text">{{ $t("menu.shops") }}</span>
        </a>
      </li>
    </router-link>
    <router-link to="/shop-posts" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon2-list"></em>
          <span class="menu-text">{{ $t("menu.shopPosts") }}</span>
        </a>
      </li>
    </router-link>
    <router-link to="/social-posts" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon2-list-3"></em>
          <span class="menu-text">{{ $t("menu.socialPosts") }}</span>
        </a>
      </li>
    </router-link>

    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open':
          hasActiveChildren('/shop-advertised-posts') ||
          hasActiveChildren('/recommended-by') ||
          hasActiveChildren('/suggested-for-you') ||
          hasActiveChildren('/blogs') ||
          hasActiveChildren('/news') ||
          hasActiveChildren('/banners') ||
          hasActiveChildren('/sectors') ||
          hasActiveChildren('/sponsors')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-address-book"></i>
        <span class="menu-text"> {{ $t("menu.advertising") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/shop-advertised-posts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.shopAdvertisedPosts") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/recommended-by" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.recommendedBy") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/blogs" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.sponsoredBy") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/suggested-for-you" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.suggestedForYou") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/news" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.eventsAndNews") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/banners" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.banners") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/banners-new/banner-definitions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.bannerDefinitions") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/banners-new/banner-instances"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.bannerInstances") }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/sectors" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-if="currentUser.superAdmin"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.mainPageSectors") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open':
          hasActiveChildren('/welcome-screen') ||
          hasActiveChildren('/coins') ||
          hasActiveChildren('/wheel-of-fortune')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-clone"></i>
        <span class="menu-text">{{ $t("menu.campaigns") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/welcome-screen" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.welcomeScreen") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/coins" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.coins") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/wheel-of-fortune" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.wheel") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link to="/scheduler" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon-calendar-with-a-clock-time-tools"></em>
          <span class="menu-text">{{ $t("menu.scheduler") }}</span>
        </a>
      </li>
    </router-link>

    <router-link to="/orders" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon-shopping-basket"></em>
          <span class="menu-text">{{ $t("menu.orders") }}</span>
        </a>
      </li>
    </router-link>
    <!--    <router-link to="/recommended-categories" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon-squares-3"></em>
          <span class="menu-text">{{ $t("menu.categories") }}</span>
        </a>
      </li>
    </router-link>
    <router-link to="/ooblee-categories" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon2-sort"></em>
          <span class="menu-text">{{ $t("menu.oobleeCategories") }}</span>
        </a>
      </li>
    </router-link>
    -->
    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open':
          hasActiveChildren('/recommended-categories') ||
          hasActiveChildren('/ooblee-categories') ||
          hasActiveChildren('/main-feed-categories')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <em class="menu-icon far flaticon-squares-3"></em>
        <span class="menu-text"> {{ $t("menu.categories") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/ooblee-categories" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.oobleeCategories") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/main-feed-categories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.mainFeedCategories") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/recommended-categories"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.recommendedCategories") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/shopping-events')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far flaticon-event-calendar-symbol"></i>
        <span class="menu-text"> {{ $t("menu.shoppingEvents") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/shopping-events/donation-goals"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.donationGoals") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/shopping-events/shopping-events"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.shoppingEvents") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/shopping-events/shopping-event-posts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.shoppingEventPosts") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/shopping-events/global-goals"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.globalGoals") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/memberships')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far flaticon-coins"></i>
        <span class="menu-text"> {{ $t("menu.memberships") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/memberships/memberships"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.memberships") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/memberships/wallet-approval"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.walletApproval") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/memberships/shops-information"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.shopsInformation") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/memberships/packages"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.packages") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/memberships/package-permissions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.packagePermissions") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/memberships/super-memberships"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.superMemberships") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open':
          hasActiveChildren('/messages') ||
          hasActiveChildren('/broadcast-messages') ||
          hasActiveChildren('/message-groups')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <em class="menu-icon far flaticon2-mail-1"></em>
        <span class="menu-text"> {{ $t("menu.messages") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/messages" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.messages") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/broadcast-messages"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.broadcast") }}</span>
              </a>
            </li>
          </router-link>
          <router-link to="/message-groups" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.groups") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/group-shopping')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far flaticon2-group"></i>
        <span class="menu-text"> {{ $t("menu.groupShopping") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/group-shopping/swf-offer"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.swfOffer") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/group-shopping/swf-configuration"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.swfConfiguration") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link to="/shop-products-importer" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far flaticon-file-1"></em>
          <span class="menu-text">{{ $t("menu.shopProductImporter") }}</span>
        </a>
      </li>
    </router-link>
    <li
      v-if="currentUser.superAdmin"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/referral') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far far flaticon-businesswoman"></i>
        <span class="menu-text"> {{ $t("menu.referral") }} </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/referral/programs" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.referralProgram") }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/referral/invitations"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.referralInvitation") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link to="/legal" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        v-if="currentUser.superAdmin"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far fas fa-gavel"></em>
          <span class="menu-text">{{ $t("menu.legal") }}</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      :class="{
        'menu-item-open': hasActiveChildren('vouchers') || hasActiveChildren('voucher-limitations')
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <em class="menu-icon far fas fa-gift"></em>
        <span class="menu-text">{{ $t("menu.vouchers") }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link to="/vouchers" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a href="#" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.voucherList") }}</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/voucher-limitations"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="currentUser.superAdmin"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t("menu.voucherLimitations") }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <router-link to="/promo-codes" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon far fas fa-percentage"></em>
          <span class="menu-text">{{ $t("menu.promoCodes") }}</span>
        </a>
      </li>
    </router-link>
    <router-link to="/general-settings" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <em class="menu-icon fas fa-cog"></em>
          <span class="menu-text">General settings</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
